<template>
  <div class="align wrapper-not-found">
    <div>
      <div class="section-header">Page Not Found</div>
      <br />
      <div class="section-paragraph">
        It looks that you've reached a URL that doesn't exits. Please use the
        navigation above to find your way back to our website.
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import "../styles/globalStyles.scss";
.wrapper-not-found {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>